import type { FC, ComponentType, Dispatch, SetStateAction } from 'react';
import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import type { QueryResult } from 'react-apollo';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Inline, Flex, xcss } from '@atlaskit/primitives';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import {
	VIEW_PAGE_CONTENT_HEADER_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import {
	Breadcrumbs,
	FixedSpaceHeaderContainer,
	FixedSpaceHeaderWithBannerContainer,
} from '@confluence/breadcrumbs';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
} from '@confluence/web-item';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { RendererContentState } from '@confluence/content-state';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useContentClassification } from '@confluence/data-classification/entry-points/queries';
import { usePageHeightWidthObserver } from '@confluence/page-cover-picture';
import { TeammatePresenceLoader } from '@confluence/experiment-teammate-presence';
import { ConvertPageToFolderBannerLoader } from '@confluence/folder/entry-points/ConvertPageToFolderBannerLoader';

import { ContentHeaderUnifiedQuery } from './ContentHeaderUnifiedQuery.graphql';
import type { ContentHeaderUnifiedQuery as ContentHeaderUnifiedQueryType } from './__types__/ContentHeaderUnifiedQuery';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from './defaultQueryVariables';
import { PageMetadataAtl } from './PageMetadataAtl';
import { PageMetadataBanner } from './PageMetadataBanner';
import { SystemContentMetadata } from './SystemContentMetadata';
import { GroupedPageButtons } from './GroupedPageButtons';
import { CONNECT_APP_WEB_FRAGMENT_METRIC } from './perf.config';

const ContentClassificationTagLoader = LoadableAfterPaint({
	name: 'ContentClassificationTag',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentClassificationTag" */ '@confluence/data-classification'
			)
		).ContentClassificationTag,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexBreadcrumbsSpacer = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpacedActionItem = styled.div({
	marginLeft: token('space.050', '4px'),
});

const BREADCRUMBS_MIN_WIDTH = '150px'; // needed for responsive breadcrumbs

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	minWidth: BREADCRUMBS_MIN_WIDTH,
});

const breadcrumbsAreaWrapper = xcss({
	flexWrap: 'wrap',
	minWidth: BREADCRUMBS_MIN_WIDTH,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Metadata = styled.div((props: MetadataProps) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	overflow: 'hidden', // needed to hide unpublished lozenge below certain header width
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: props.shouldDisplayClassification ? '20px' : '30px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .content-metadata-jira': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginTop: props.shouldDisplayClassification ? '-5px' : '0',
	},
}));

SpacedActionItem.displayName = 'SpacedActionItem';

const EXCLUDED_SYSTEM_IDS = [
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
];

const SYSTEM_IDS_TO_SHOW_WITH_PAGE_BUTTONS = [CONTENT_METADATA_PAGE_RESTRICTIONS_ID];

type MetadataProps = {
	shouldDisplayClassification: boolean;
};

type RenderContentHeaderProps = {
	contentType: string | null | undefined;
	contentTitle: string | null | undefined;
	isFabricPage: boolean;
	loading: boolean;
	subType: string | null | undefined;
	lastModifiedDate: string | null | undefined;
	isThemed: boolean;
	isContentToolsForcedOpen: boolean;
	setIsContentToolsForcedOpen: Dispatch<SetStateAction<boolean>>;
};

type RenderBreadcrumbsProps = {
	contentType: string | null | undefined;
	contentTitle: string | null | undefined;
	contentId: string;
	isCustomSitesPageTitleFFOn: boolean;
};

type ContentHeaderProps = {
	spaceKey: string;
	contentId: string;
	actionItems?: ComponentType<any>[];
	versionOverride?: number;
	className?: string;
	showMetadataItems?: boolean;
};

export const ContentHeader: FC<ContentHeaderProps> = ({
	spaceKey,
	contentId,
	actionItems = [],
	versionOverride = null,
	showMetadataItems = true,
}) => {
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);

	const isLivePagesEnabledLD = useBooleanFeatureFlag('confluence.frontend.livepages.enable');
	const isLivePagesEnabledSS = FeatureGates.checkGate('confluence_live_pages');
	const isLivePagesEnabled = isLivePagesEnabledLD || isLivePagesEnabledSS;

	const isFolderConversionEnabled = FeatureGates.checkGate(
		'confluence_enable_page_to_folder_conversion',
	);

	const [isContentToolsForcedOpen, setIsContentToolsForcedOpen] = useState(false);

	const { isExternalCollaborator } = useIsExternalCollaborator();
	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext: 'current-only',
	});

	const { shouldDisplayClassification } = contentClassificationMetadata;

	// These values are entirely empirical and expected to be a limited-time workaround to fix the wrapping issue for the classification tag.
	// Will be removed when https://product-fabric.atlassian.net/browse/NARU-1170 is worked on.
	const leftRightPaddingOffset = -37;
	const widthLimitForMetadata = 1250;

	const { width } = usePageHeightWidthObserver(
		isCustomSitesPageTitleFFOn,
		false,
		leftRightPaddingOffset,
		false,
	);

	const renderBreadcrumbs = ({
		contentType,
		contentTitle,
		contentId,
		isCustomSitesPageTitleFFOn,
	}: RenderBreadcrumbsProps) => {
		return (
			<Flex alignItems="center" xcss={breadcrumbsAreaWrapper}>
				<Breadcrumbs contentId={contentId} spaceKey={spaceKey} contentTitle={contentTitle || ''} />
				<Inline alignBlock="center">
					{showMetadataItems &&
						(!shouldDisplayClassification ||
							(shouldDisplayClassification && width > widthLimitForMetadata)) && (
							<Metadata
								data-testid="metadata-wrapper"
								shouldDisplayClassification={shouldDisplayClassification}
							>
								<SystemContentMetadata
									key={`metadata-${contentId}`}
									contentId={contentId}
									versionOverride={versionOverride}
									excludeIds={EXCLUDED_SYSTEM_IDS}
								/>
								<PageMetadataBanner
									key={`banner-${contentId}`}
									contentId={contentId}
									versionOverride={versionOverride}
								/>
							</Metadata>
						)}
				</Inline>
				{isCustomSitesPageTitleFFOn && (
					<div
						data-testid="content-classification-tag"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ flexBasis: '100%' }}
					>
						<ContentClassificationTagLoader
							contentId={contentId}
							contentType={contentType || null}
							customSitesTitleComponent
							contentClassificationMetadata={contentClassificationMetadata}
							isEditMode={false}
						/>
					</div>
				)}
			</Flex>
		);
	};

	const renderContentHeader = ({
		contentType,
		contentTitle,
		isFabricPage,
		loading,
		subType,
		lastModifiedDate,
		isContentToolsForcedOpen,
		setIsContentToolsForcedOpen,
	}: RenderContentHeaderProps) => {
		return (
			<Fragment>
				<BreadcrumbContainer data-testid="breadcrumb">
					{renderBreadcrumbs({
						contentType,
						contentTitle,
						contentId,
						isCustomSitesPageTitleFFOn,
					})}
				</BreadcrumbContainer>
				<FlexBreadcrumbsSpacer />
				{showMetadataItems && <PageMetadataAtl contentId={contentId} />}
				<TeammatePresenceLoader
					contentId={contentId}
					contentType={contentType}
					spaceKey={spaceKey}
				/>
				<Fragment>
					<RendererContentState contentId={contentId} isPagePreview={false} />
					<GroupedPageButtons
						contentId={contentId}
						version={versionOverride}
						isFabricPage={isFabricPage}
						includeSystemIds={SYSTEM_IDS_TO_SHOW_WITH_PAGE_BUTTONS}
						spaceKey={spaceKey}
						contentType={contentType}
						contentSubType={subType}
						isExternalCollaborator={isExternalCollaborator}
						lastModifiedDate={lastModifiedDate}
						showEdit={!(isLivePagesEnabled && subType === 'live')}
						isContentToolsForcedOpen={isContentToolsForcedOpen}
						setIsContentToolsForcedOpen={setIsContentToolsForcedOpen}
					/>
				</Fragment>

				{actionItems?.map((ActionItem: ComponentType<any>, index) => {
					const actionItemProps = {
						contentId,
						spaceKey,
						contentType,
					};
					return (
						<SpacedActionItem
							data-testid="spacedActionItemTestId"
							key={ActionItem?.displayName || index}
						>
							<ActionItem {...actionItemProps} disabled={loading} />
						</SpacedActionItem>
					);
				})}
			</Fragment>
		);
	};

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			{showMetadataItems && (
				<PageSegmentLoadStart
					key={`PageSegmentLoadStart-connect-${contentId}`}
					metric={CONNECT_APP_WEB_FRAGMENT_METRIC}
				/>
			)}
			<ExperienceStart id={contentId} name={VIEW_PAGE_CONTENT_HEADER_EXPERIENCE} />
			<Query
				query={ContentHeaderUnifiedQuery}
				variables={{
					contentId,
					spaceKey,
					// Don't omit versionOverride, embeddedContentRender and limit
					// It's an Apollo bug that if preloader these needs to be match preloaded query
					// Also doesn't work if removing them from preloader variables HOT-109035
					versionOverride: versionOverride || null,
					embeddedContentRender: null,
					limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
				}}
				context={{ single: true }}
			>
				{({ loading, error, data }: QueryResult<ContentHeaderUnifiedQueryType>) => {
					if (error) {
						if (isUnauthorizedError(error)) {
							markErrorAsHandled(error);
							return null;
						} else {
							// TODO: unknown errors should have a nicer UI within `<ErrorDisplay>`, but we don't have such UI for now
							return <ErrorDisplay error={error} />;
						}
					} else if (!data) {
						return null;
					}

					const contentNode = data?.content?.nodes?.[0];
					const contentType = contentNode?.type;
					const lastModifiedDate = contentNode?.metadata?.lastModifiedDate;
					const subType = contentNode?.subType;
					const contentTitle = contentNode?.title;
					// property can be "v2" or v2
					const isFabricPage =
						contentNode?.properties?.nodes?.[0]?.value === '"v2"' ||
						contentNode?.properties?.nodes?.[0]?.value === 'v2';

					const spaceIsThemed = Boolean(data?.space?.theme?.themeKey);

					if (isFolderConversionEnabled) {
						return (
							<FixedSpaceHeaderWithBannerContainer
								isThemed={spaceIsThemed}
								testId="content-header-container"
								banner={
									<ConvertPageToFolderBannerLoader
										contentId={contentId}
										spaceKey={spaceKey}
										contentType={contentType}
										onActionButtonClick={() => setIsContentToolsForcedOpen(true)}
									/>
								}
							>
								{renderContentHeader({
									contentType,
									contentTitle,
									isFabricPage,
									loading,
									subType,
									lastModifiedDate,
									isThemed: spaceIsThemed,
									isContentToolsForcedOpen,
									setIsContentToolsForcedOpen,
								})}
							</FixedSpaceHeaderWithBannerContainer>
						);
					}

					return (
						<FixedSpaceHeaderContainer
							isThemed={spaceIsThemed}
							data-testid="content-header-container"
						>
							{renderContentHeader({
								contentType,
								contentTitle,
								isFabricPage,
								loading,
								subType,
								lastModifiedDate,
								isThemed: spaceIsThemed,
								isContentToolsForcedOpen,
								setIsContentToolsForcedOpen,
							})}
						</FixedSpaceHeaderContainer>
					);
				}}
			</Query>
		</ErrorBoundary>
	);
};
