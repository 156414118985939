import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { ConfluencePageAri } from '@atlassian/ari/confluence';
import { useQuery, useApolloClient } from 'react-apollo';

import { PageUnifiedQueryV2 } from './PageUnifiedQueryV2.graphql';
import { ContentBlogUnifiedQuery } from './ContentBlogUnifiedQuery.graphql';
import { ContentUnifiedQuery } from './ContentUnifiedQuery.graphql';
import {
	canUsePageUnifiedQueryResult,
	convertToContentUnifiedQueryResult,
} from './pageUnifiedQueryV2Transformer';
import {
	PageUnifiedQueryV2Type,
	PageUnifiedQueryV2VariablesType,
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
	ContentBlogUnifiedQueryType,
	ContentBlogUnifiedQueryVariablesType,
} from '.';

type chooseContentUnifiedQueryType = ContentUnifiedQueryType | ContentBlogUnifiedQueryType;

type chooseContentUnifiedQueryVariablesType = ContentUnifiedQueryVariablesType &
	ContentBlogUnifiedQueryVariablesType;

export function useContentUnifiedQuery({
	commonConfig = {},
	contentUnifiedQueryConfig,
	pageUnifiedQueryConfig,
	knownContentType,
}) {
	const client = useApolloClient();
	const isSSR = Boolean(process.env.REACT_SSR);

	const chooseContentUnifiedQuery =
		knownContentType === 'blogpost' ? ContentBlogUnifiedQuery : ContentUnifiedQuery;

	const isMigratingContentUnifiedQuery =
		useBooleanFeatureFlag('confluence.frontend.content.unified.query.migration') && isSSR;

	const { cloudId } = useSessionData();
	const contentId = contentUnifiedQueryConfig.variables.contentId;

	let ari;

	try {
		ari = ConfluencePageAri.create({
			siteId: cloudId,
			pageId: contentId,
		}).toString();
	} catch (e) {
		// Do nothing
	}

	const pageUnifiedQuerySkipped =
		pageUnifiedQueryConfig.skip ||
		!ari ||
		!(isMigratingContentUnifiedQuery && knownContentType !== 'blogpost');

	const pageUnifiedQueryResult = useQuery<PageUnifiedQueryV2Type, PageUnifiedQueryV2VariablesType>(
		PageUnifiedQueryV2,
		{
			...commonConfig,
			...pageUnifiedQueryConfig,
			variables: { ...pageUnifiedQueryConfig.variables, contentId: ari },
			skip: pageUnifiedQuerySkipped,
		},
	);

	const contentUnifiedQueryResult = useQuery<
		chooseContentUnifiedQueryType,
		chooseContentUnifiedQueryVariablesType
	>(chooseContentUnifiedQuery, {
		...commonConfig,
		...contentUnifiedQueryConfig,
		variables: contentUnifiedQueryConfig.variables,
		skip:
			contentUnifiedQueryConfig.skip ||
			canUsePageUnifiedQueryResult({
				pageUnifiedQueryResult,
				isMigratingContentUnifiedQuery,
				pageUnifiedQuerySkipped,
			}),
	});

	const canUsePageQuery = canUsePageUnifiedQueryResult({
		pageUnifiedQueryResult,
		isMigratingContentUnifiedQuery,
		pageUnifiedQuerySkipped,
	});

	const contentQueryResult = canUsePageQuery ? pageUnifiedQueryResult : contentUnifiedQueryResult;

	const data: chooseContentUnifiedQueryType | undefined = canUsePageQuery
		? convertToContentUnifiedQueryResult(contentQueryResult.data as PageUnifiedQueryV2Type)
		: (contentQueryResult.data as chooseContentUnifiedQueryType);

	if (Boolean(data) && canUsePageQuery) {
		try {
			client.writeQuery({
				query: chooseContentUnifiedQuery,
				variables: contentUnifiedQueryConfig.variables,
				data,
			});
		} catch (e) {
			// do nothing
		}
	}

	return { ...contentQueryResult, data };
}
