import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import type { FC } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';

import { usePreloadCommentButton } from './usePreloadCommentButton';
import { CommentButtonPlaceholder } from './CommentButtonPlaceholder';
import { CommentButtonLazy } from './CommentButtonLazy';

type CommentButtonWrapperProps = {
	contentId: string;
	isFabricPage: boolean;
	isCommentButtonSSRd?: boolean;
};

const noop = () => {};

const SSR_ACTION_LOADING_SPINNER_STYLE = `padding: 0 1px;`;

export const CommentButtonWrapper: FC<CommentButtonWrapperProps> = memo(
	({ contentId, isCommentButtonSSRd, isFabricPage }) => {
		const { data: commentData, error } = usePreloadCommentButton(contentId, isCommentButtonSSRd);

		const isItemMarked = useRef<boolean>(false);
		const markItem = useRef<any>(null);
		const [showCommentButton, setShowCommentButton] = useState(false);

		const buttonRendered = () => {
			if (!isItemMarked.current) {
				isItemMarked.current = true;
				markItem.current(HEADER_ITEM_KEYS.PAGE_COMMENTS);
			}
		};

		/* Scenario: A user has clicked on the button in SSR -> SPA transition.
     The mouse click event is captured and the loading of CommentButtonLazy is started.
     */
		const handleCommentButtonClick = useCallback(
			(evt: any) => {
				if (isCommentButtonSSRd) {
					if (!window.__SSR_EVENTS_CAPTURE__) {
						window.__SSR_EVENTS_CAPTURE__ = {};
					}

					window.__SSR_EVENTS_CAPTURE__.inlineCommentButton = evt;
				}

				setShowCommentButton(true);
			},
			[isCommentButtonSSRd],
		);

		/* Scenario: A user has clicked on the button in SSR.
     The mouse click event is captured and executed immediately on Placeholder component mount
     (ie. kickstarting the loading of the CommentButtonLazy component)
     */
		useEffect(() => {
			if (isCommentButtonSSRd && window.__SSR_EVENTS_CAPTURE__?.inlineCommentButton) {
				setShowCommentButton(true);
			}
		}, [isCommentButtonSSRd]);

		return (
			<Subscribe to={[HeaderItemsContainer]}>
				{(headerItemsContainer: HeaderItemsContainer) => {
					headerItemsContainer.addItem(HEADER_ITEM_KEYS.PAGE_COMMENTS);
					markItem.current = headerItemsContainer.markItem;

					/*Conditional Loading of CommentButtonLazy if FF is ON and showCommentButton true (user click)*/

					/* in case of an error from CommentButtonQuery load CommentButtonLazy and allow CommentButton to make
          another network call to fetch data*/
					const isDataLoaded = Boolean(commentData && !error);

					if (showCommentButton || error) {
						return (
							<>
								<CommentButtonLazy
									contentId={contentId}
									onRender={buttonRendered}
									isClickedInSSR={
										isCommentButtonSSRd &&
										Boolean(window.__SSR_EVENTS_CAPTURE__?.inlineCommentButton)
									}
									isDataLoaded={isDataLoaded}
									isFabricPage={isFabricPage}
								/>
							</>
						);
					} else {
						return (
							<>
								{process.env.REACT_SSR && (
									<SSRActionLoadingSpinner
										spinnerId="inline-comment-loading-spinner"
										actionType="inlineCommentButton"
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
										style={SSR_ACTION_LOADING_SPINNER_STYLE}
									/>
								)}
								<CommentButtonPlaceholder
									isDisabled={!isDataLoaded && !isCommentButtonSSRd}
									handleCommentButtonClick={
										isDataLoaded || isCommentButtonSSRd ? handleCommentButtonClick : noop
									}
									onRender={buttonRendered}
									contentId={contentId}
								/>
							</>
						);
					}
				}}
			</Subscribe>
		);
	},
);
