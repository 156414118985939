import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { isFedRamp } from '@atlassian/atl-context';

import { VIEW_PAGE_SHARE_BUTTON_EXPERIENCE, ExperienceStart } from '@confluence/experience-tracker';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { INVITE_TO_EDIT_KEY } from '@confluence/web-item';
import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog';
import { WebItemLocation, PAGE_BUTTONS } from '@confluence/web-item-location';
import { PageGroupedButtonsAutomationMenu } from '@confluence/automation-menu/entry-points/PageGroupedButtonsAutomationMenu';
import { WebItemButtonList } from '@confluence/web-item-list';
import { FavouriteButton } from '@confluence/favourite-button';
import { PageHeaderLoomEntryPoint } from '@confluence/page-header-loom-button';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ConfluenceEdition } from '@confluence/change-edition';
import { WatchDialogWrapper, WATCH_DIALOG_LOCATION } from '@confluence/watch-dialog';
import { CommentButtonWrapper } from '@confluence/comment-button';
import { ShareButtonPlaceholder } from '@confluence/share/entry-points/ShareButtonPlaceholder';
import { RestrictionsDialogTriggerWrapper } from '@confluence/restrictions';
import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { QuickSummaryPopup } from '@confluence/quick-summary';
import { CopyLinkButtonLoader } from '@confluence/share';
import { AIContentActionsMenuSplitButton } from '@confluence/ai-content-actions';
import { ShareAndRestrictButtonPlaceholder } from '@confluence/share-and-restrict-dialog/entry-points/ShareAndRestrictButtonPlaceholder';

import { GroupedPageButtonsCommon, FILTERED_WEB_ITEM_KEYS } from './GroupedPageButtonsCommon';
import { CONFLUENCE_EDITION as Edition } from './defaultQueryVariables';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWebItemButtonList = styled(WebItemButtonList)({
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBorder = styled.div({
	borderLeft: `2px solid ${token('color.border', 'rgba(107, 119, 140, 0.3)')}`,
	display: 'inline-block',
	width: '1px',
	height: '16px',
	margin: `0 ${token('space.075', '6px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WebItemsContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '100%',
});

// DISCO-557 add maxWidth to system content button panel to limit amount of pageheader horizontal space that can be taken up by third party buttons
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SystemContentButtonMaxWidthContainer = styled.div({
	maxWidth: '200px',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShareButtonPlaceholderSpacer = styled.span({
	marginLeft: token('space.100', '8px'),
});

const ContentHeaderShareAndCopyButtons = ({
	contentId,
	versionOverride,
	contentType,
	contentSubType,
	spaceKey,
}) => {
	return (
		<Subscribe to={[HeaderItemsContainer]}>
			{({ addItem }) => (
				<SeparateShareAndCopyButtons
					contentId={contentId}
					addItem={addItem}
					versionOverride={versionOverride}
					contentType={contentType}
					contentSubType={contentSubType}
					spaceKey={spaceKey}
				/>
			)}
		</Subscribe>
	);
};

ContentHeaderShareAndCopyButtons.propTypes = {
	contentId: PropTypes.string.isRequired,
	versionOverride: PropTypes.number,
	contentType: PropTypes.string,
	contentSubType: PropTypes.string,
	spaceKey: PropTypes.string.isRequired,
};

const SeparateShareAndCopyButtons = ({
	contentId,
	spaceKey,
	addItem,
	versionOverride,
	contentType,
	contentSubType,
}) => {
	const isShareAndRestrictDialogFfEnabled = FeatureGates.checkGate(
		'confluence_frontend_unified_restrict_and_share',
	);

	return (
		<StyledContainer>
			{/* Copy link */}
			<WebItemLocation
				key="copylink-button"
				tagName="span"
				contentId={contentId}
				allowedWebItems={[INVITE_TO_EDIT_KEY]}
				location={PAGE_BUTTONS}
				version={versionOverride}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ display: 'flex' }}
				renderWhenLoading
			>
				{({ webItems }) => {
					// Copy link button appears whenever Share dialog does (depending on experiment cohort),
					// except for on embedded pages.
					const foundShareWebItem =
						webItems?.length && webItems.some((item) => item.completeKey === INVITE_TO_EDIT_KEY);
					const canShowCopyLink = foundShareWebItem;

					if (canShowCopyLink) {
						addItem(HEADER_ITEM_KEYS.COPY_LINK);
						return (
							<CopyLinkButtonLoader
								contentId={contentId}
								spaceKey={spaceKey}
								contentType={contentType}
								pageMode="view"
								source="contentHeader"
							/>
						);
					}
					return null;
				}}
			</WebItemLocation>
			{/* Share button */}
			<ExperienceStart
				key="view-page-share-button-start"
				id={contentId}
				name={VIEW_PAGE_SHARE_BUTTON_EXPERIENCE}
			/>
			<WebItemLocation
				key="share-button"
				tagName="span"
				contentId={contentId}
				allowedWebItems={[INVITE_TO_EDIT_KEY]}
				location={PAGE_BUTTONS}
				version={versionOverride}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ display: 'flex' }}
				renderWhenLoading
			>
				{({ loading, webItems }) => {
					if (loading) {
						return (
							<ShareButtonPlaceholderSpacer>
								{isShareAndRestrictDialogFfEnabled ? (
									<ShareAndRestrictButtonPlaceholder />
								) : (
									<ShareButtonPlaceholder />
								)}
							</ShareButtonPlaceholderSpacer>
						);
					}
					webItems.forEach((item) => {
						if (item.completeKey === INVITE_TO_EDIT_KEY) {
							// ShareButton can now fetch with a provided contentId
							item.contentId = contentId;
							item.contentType = contentType;
							addItem(HEADER_ITEM_KEYS.SHARE_PAGE);
						}
					});

					return (
						<StyledWebItemButtonList
							pageId={contentId}
							webItems={webItems}
							id="extracted-share-button"
							contentSubType={contentSubType}
						/>
					);
				}}
			</WebItemLocation>
		</StyledContainer>
	);
};

SeparateShareAndCopyButtons.propTypes = {
	contentId: PropTypes.string.isRequired,
	spaceKey: PropTypes.string.isRequired,
	addItem: PropTypes.func,
	versionOverride: PropTypes.number,
	contentType: PropTypes.string,
	contentSubType: PropTypes.string,
};

export const GroupedPageButtons = (props) => {
	const {
		contentId,
		versionOverride,
		isExternalCollaborator,
		spaceKey,
		isFabricPage,
		contentSubType,
		contentType,
		lastModifiedDate,
	} = props;
	const { edition, isAdminHubAIEnabled } = useSessionData();
	const { isSiteAdmin, isAnonymous } = useContext(SPAViewContext);

	const isShareAndRestrictDialogFfEnabled = FeatureGates.checkGate(
		'confluence_frontend_unified_restrict_and_share',
	);

	const showQuickSummary =
		isAdminHubAIEnabled || isSiteAdmin || edition !== ConfluenceEdition.PREMIUM;

	const showCommentsSummary =
		isAdminHubAIEnabled &&
		FeatureGates.getExperimentValue(
			'cc_smarts_inline_comments_summary',
			'comments_summary_position',
			'control',
		) !== 'control';

	const showPageCatchup =
		isAdminHubAIEnabled &&
		FeatureGates.getExperimentValue('cc_smarts_page_catchup', 'cohort', 'control') === 'test';

	const isAiPresentationEnabled = useBooleanFeatureFlag(
		'confluence.frontend.ai.presentation.generation',
	);
	const showAIPresentationGeneration = isAdminHubAIEnabled && isAiPresentationEnabled;

	const shouldShowAIActionsMenu =
		showCommentsSummary || showPageCatchup || showAIPresentationGeneration;

	const shouldShowAutomation =
		edition === Edition.PREMIUM &&
		contentType === 'page' &&
		!isExternalCollaborator &&
		!isAnonymous;

	return (
		<GroupedPageButtonsCommon {...props}>
			<CommentButtonWrapper
				key={contentId}
				contentId={contentId}
				isCommentButtonSSRd={Boolean(window.__SSR_RENDERED__ || process.env.REACT_SSR)}
				isFabricPage={isFabricPage}
			/>
			<PageHeaderLoomEntryPoint
				contentId={contentId}
				spaceKey={spaceKey}
				contentType={contentType}
			/>
			{shouldShowAutomation && (
				<PageGroupedButtonsAutomationMenu
					key={`automation-manual-triggers-${contentId}`}
					pageId={contentId}
					spaceKey={spaceKey}
				/>
			)}
			<FavouriteButton key="favourite-button" contentId={contentId} />
			<WatchDialogWrapper
				key="watch-dialog"
				contentId={contentId}
				defaultTab={MANAGE_WATCHER_TAB_TYPES.PAGE}
				componentLocation={WATCH_DIALOG_LOCATION.PAGE}
			/>
			{!isFedRamp() && (showQuickSummary || shouldShowAIActionsMenu) && (
				<StyledContainer>
					<StyledBorder />
					{shouldShowAIActionsMenu ? (
						<AIContentActionsMenuSplitButton
							key={contentId}
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
							lastModifiedDate={lastModifiedDate}
							source="page"
						/>
					) : (
						<QuickSummaryPopup
							key={contentId}
							contentId={contentId}
							contentType={contentType}
							contentPath={window.location.pathname}
							lastModifiedDate={lastModifiedDate}
							source="page"
						/>
					)}
				</StyledContainer>
			)}
			{/* Buttons other than edit, favourite, watch and share */}
			<WebItemLocation
				key="page-buttons"
				contentId={contentId}
				location={PAGE_BUTTONS}
				notAllowedWebItems={FILTERED_WEB_ITEM_KEYS}
				tagName="span"
				version={versionOverride}
			>
				{({ webItems }) => (
					<WebItemsContainer>
						{!!webItems.length && <StyledBorder />}
						<SystemContentButtonMaxWidthContainer>
							<StyledWebItemButtonList
								webItems={webItems}
								buttonType="aui-button-subtle"
								contentSubType={contentSubType}
							/>
						</SystemContentButtonMaxWidthContainer>
					</WebItemsContainer>
				)}
			</WebItemLocation>

			{/* Restrictions and share buttons */}
			{!isExternalCollaborator && (
				<>
					<StyledBorder />
					{!isShareAndRestrictDialogFfEnabled && (
						<RestrictionsDialogTriggerWrapper
							triggeredFrom="fromView"
							contentId={contentId}
							contentSubType={contentSubType}
						/>
					)}
					<ContentHeaderShareAndCopyButtons
						contentId={contentId}
						versionOverride={versionOverride}
						contentType={contentType}
						contentSubType={contentSubType}
						spaceKey={spaceKey}
					/>
				</>
			)}
		</GroupedPageButtonsCommon>
	);
};

GroupedPageButtons.propTypes = {
	spaceKey: PropTypes.string.isRequired,
	contentId: PropTypes.string.isRequired,
	versionOverride: PropTypes.number,
	headerSticky: PropTypes.bool,
	isFabricPage: PropTypes.bool,
	contentType: PropTypes.string,
	contentSubType: PropTypes.string,
	isExternalCollaborator: PropTypes.bool,
	lastModifiedDate: PropTypes.string,
};
