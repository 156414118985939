import React, { PureComponent } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';

import { EditContentButton } from './EditContentButton';

export class EditContentButtonWrapper extends PureComponent {
	static propTypes = {
		contentId: EditContentButton.propTypes?.contentId,
		isCompanyHubButton: EditContentButton.propTypes?.isCompanyHubButton,
	};

	isItemMarked = false;
	markItem = null;

	buttonRendered = () => {
		if (!this.isItemMarked) {
			this.isItemMarked = true;
			this.markItem(HEADER_ITEM_KEYS.EDIT_PAGE);
		}
	};

	render() {
		return (
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem }) => {
					addItem(HEADER_ITEM_KEYS.EDIT_PAGE);
					this.markItem = markItem;
					return <EditContentButton {...this.props} onRender={this.buttonRendered} />;
				}}
			</Subscribe>
		);
	}
}
