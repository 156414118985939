import { LoadablePaint, LoadableLazy } from '@confluence/loadable';

import { ForgeContentToolsPlaceholder } from './ForgeContentToolsPlaceholder';

export const ContentToolsWrapper = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentToolsWrapper" */ './ContentToolsWrapper'))
			.ContentToolsWrapper,
});

export const ForgeContentTools = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ForgeContentTools" */ './ForgeContentTools'))
			.ForgeContentTools,

	loading: ForgeContentToolsPlaceholder,
});
